"use client";

import { FC, PropsWithChildren } from "react";

import { useSetScrollbarWidth } from "@Shared/hooks";

export const ClientWrapper: FC<PropsWithChildren> = ({ children }) => {
    useSetScrollbarWidth();

    return children;
};
