import(/* webpackMode: "eager" */ "/home/app/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layouts/Root.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-montserrat\",\"display\":\"swap\"}],\"variableName\":\"fontPrimary\"}");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layouts/Root.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"fontSecondary\"}");
;
import(/* webpackMode: "eager", webpackExports: ["toast"] */ "/home/app/frontend/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/app/providers/ClientWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/app/styles/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/home/app/frontend/src/features/contact-us/ui/modal-form/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Form"] */ "/home/app/frontend/src/features/contact-us/ui/modal-form/Form.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/assets/images/logo.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/i18n/client.ts");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/button/Button.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/button/ButtonSubmit.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-down-sm.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-right.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/angle-top-sm.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/arrow-left.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/arrow-top-right.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/behance.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/close-circle.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/close.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/globe.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/image.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/instagram.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/lightning.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/menu-close.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/menu.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/rhombus-rounded.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/images/telegram.svg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/icon/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ImageWithLoader"] */ "/home/app/frontend/src/shared/ui/image/ImageWithLoader.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/input/Input.tsx");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/shared/ui/wysiwyg/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/widgets/footer/ui/images/bg-d.jpg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/widgets/footer/ui/images/bg.jpg");
;
import(/* webpackMode: "eager" */ "/home/app/frontend/src/widgets/footer/ui/Languages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TitleWithButton"] */ "/home/app/frontend/src/widgets/footer/ui/TitleWithButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Buttons"] */ "/home/app/frontend/src/widgets/header/ui/Buttons.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/home/app/frontend/src/widgets/header/ui/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Container"] */ "/home/app/frontend/src/widgets/nav/ui/Container.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Links"] */ "/home/app/frontend/src/widgets/nav/ui/Links.tsx");
